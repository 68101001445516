.SocialLinks{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 60vw;
  justify-content: center;
}

.SocialLinkImageContainer{
  display: flex;
  justify-content: center;
  width: 5vw;
  min-width: 80px;
  height: 3vw;
  min-height: 50px;
  padding-left: 1vw;
  padding-right: 1vw;
  padding-top: 3vh;
  vertical-align: middle;
}


.SocialLinkImage{
  filter: invert(75%) sepia(0%) brightness(50%);
  transition: transform .4s;
}

.Soundcloud{
  padding-right: 20px;
}

@media (max-width:700px) {
  .break{
    flex-basis: 100%;
  }
  .Instagram{
     padding-right: 20px;
   }
}

@media (max-width:400px) {
  .Soundcloud{
    padding-right: 0;
  }
  .break-all{
    flex-basis: 100%;
  }
  .Instagram{
    padding-right: 0;
  }
}

#Contact:hover{
  filter: invert(41%) sepia(94%) saturate(1417%) hue-rotate(200deg) brightness(100%) contrast(93%);
  transform: scale(1.2);
}

#Youtube:hover{
  filter: invert(30%) sepia(82%) saturate(6184%) hue-rotate(353deg) brightness(96%) contrast(138%);
  transform: scale(1.2);
}

#Instagram:hover{
  filter: invert(0%) brightness(100%);
  transform: scale(1.2);
}

#Soundcloud:hover{
  filter: invert(52%) sepia(59%) saturate(3571%) hue-rotate(3deg) brightness(102%) contrast(112%);
  transform: scale(1.2);
}

.InstagramHidden{
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  visibility: hidden;
  width:0;
  height:0;
}