.FenrisText{
  display: inline-block;
}

#FenrisText{
  filter: invert(58%) sepia(4%) saturate(17%) hue-rotate(330deg) brightness(100%) contrast(91%);
  margin-top:20vh;
  width: 70vw;
  max-height: 40vh;
  margin-bottom: 10vh;
  transition: transform .4s;
}

#FenrisText:hover{
  filter: invert(58%) sepia(4%) saturate(17%) hue-rotate(330deg) brightness(120%) contrast(91%);
  transform: scale(1.2);
}