.App {
  text-align: center;
}

.FenrisMain{
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
}

.FenrisTextContainer{
}

.SocialLinksContainer{
  height: 20vh;
  padding-top: 5vh;
  display: flex;
  width: 100vw;
  flex-direction: row;
  justify-content: center;
}