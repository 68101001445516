.FenrisBackground{

}

.FenrisBackgroundPattern{
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-image: url("./background-pattern.webp");
  filter: brightness(40%);
}

.FenrisBackgroundFilter{
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  //box-shadow: inset 0 0 20vw 5vw rgba(0, 0, 0, 0.2);
  background: rgb(255,255,255);
  background: radial-gradient(circle, rgba(255,255,255,0.08727240896358546) 45%, rgba(0,0,0,0.7) 100%);
}

.RGB {
  filter: invert(0%) sepia(99%) saturate(288%) hue-rotate(0deg) brightness(40%) contrast(87%);
  -webkit-animation: filter-animation 8s infinite;
  animation: filter-animation 8s infinite;
}

@-webkit-keyframes filter-animation {
  0% {
    -webkit-filter: invert(0%) sepia(99%) saturate(288%) hue-rotate(0deg) brightness(40%) contrast(100%);
  }

  50% {
    -webkit-filter: invert(0%) sepia(99%) saturate(288%) hue-rotate(179deg) brightness(40%) contrast(100%);
  }

  100% {
    -webkit-filter: invert(0%) sepia(99%) saturate(288%) hue-rotate(358deg) brightness(40%) contrast(100%);
  }
}

@keyframes filter-animation {
  0% {
    filter: invert(0%) sepia(99%) saturate(288%) hue-rotate(0deg) brightness(40%) contrast(100%);
  }

  50% {
    filter: invert(0%) sepia(99%) saturate(288%) hue-rotate(179deg) brightness(40%) contrast(100%);
  }

  100% {
    // 6933
    filter: invert(0%) sepia(99%) saturate(288%) hue-rotate(358deg) brightness(40%) contrast(100%);
  }
}